.navbar {
    background: rgb(231, 225, 217);
    height: 5rem;
    width: 100vw;
    margin: 0 auto;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 3;
}

.logo {
     object-fit: cover;
     height: 6rem;
     width: 6rem;
     opacity: 400%;
}

.desktopMenuListItem {
    color: black;
    margin: 1rem;
    cursor: pointer;
}

.desktopMenuListItem:hover {
    color:rgb(139, 136, 129);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(139, 136, 129);
}

.desktopMenuBtn {
    background: white;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    border-radius: 0.5rem;
    font-size: large;
}

.active {
    color: rgb(20, 17, 20);
    padding-bottom: 0.5rem;
    border-bottom: 3px solid rgb(20, 17, 20);
}