#projects {
    overflow: hidden;
    width: 100vw;
    max-width: 120rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0 auto;
}

.projectTitle {
    font-size: 3rem;
    font-weight: 280;
    padding-top: 3rem;
    margin-bottom: 1.5rem;
    color: #00416a;
}

.projectDesc {
    font-weight: 300;
    font-size: 1rem;
    max-width: 51rem;
    padding: 0 2rem;
    color: #00416a;
}

.projectBars {
    margin: 1.5rem;
    width: 100vw;
    max-width: 80%;
    text-align: left;
}

.projectBar {
    display: flex;
    margin: 1rem;
    padding: 1.5rem 2rem;
    border-radius: 0.75rem;
    background: rgb(240, 220, 201);
}

.projectBarImg{
    object-fit: cover;
    height: 3rem;
    width: 3rem;
    margin-right: 3rem;
}

.projectBarText>h2 {
    color: #3e4c5b;
    font-weight: 500;
}

.ProjectCalculatorSC,
.ProjectWebsiteSC,
.ProjectExtensionSC {
    color: #3e4c5b;
    font-weight: 500;
    font-size: x-large;
}

.projectBarText>p {
    font-size: 0.9rem;
    font-weight: 400;
    color: #3e4c5b;
}

.projectBarText ul {
    padding-left: 1.5rem;
    list-style: disc;
    margin: 0;
}

@media screen and (max-width:480px) {
    .projectBarText>p {
        font-size: 3vw;
    }
    .projectBarImg {
        height: 2.25rem;
        width: 2.25rem;
    }
    .projectBarText>h2 {
        font-size: 5vw;
    }
}