#intro {
    height: calc(100vh - 4rem);
    width: 100vw;
    max-width: 100rem;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
    margin-top: 75px;
}

.bg {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    object-fit: cover;
    max-height: calc(100vh - 24rem);
    border-radius: 2rem;
}

.introContent {
    height: 100vh;
    max-width: 60%;
    padding: 2rem;
    font-size: 3rem;
    font-weight: 700;
    flex-direction: column;
    justify-content: center;
    margin-top: 60px;
}

.hello {
    font-size: 2.5rem;
    font-weight: 550;
    color: #00416a;
}

.introPara {
    font-size: large;
    font-weight: 150;
    letter-spacing: 0.5px;
    margin-top: 30px;
    color: #00416a;
}   

.social-media-icons {
    margin-top: 15px;
    padding: 0.75rem 0rem;
    margin-left: -10px;
}

.social-icon {
    margin: 0 10px;
    color: #00416a;
}

.social-icon:hover{
    color: rgb(165, 204, 228);
}

@media screen and (max-width:840px) {
    .bg {
        right: -10vw;
    }
    .introContent {
        font-size: 10vw;
        max-width: 20%;
    }
    .hello {
        font-size: 4.5vw;
    }
}

@media screen and (max-width:480px) {
    .bg {
        right: -20vw;
    }
}